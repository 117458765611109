.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #c87e7e;
  padding: 2% 5%;
  font-weight: 700;
  margin-bottom: 40px;
}

.header a {
  color: #fff;
  text-decoration: none;
  font-size: 20px;
}

.header a:hover {
  color: #ff6d6d;
}

.menu-icon-mobile {
  color: #ff6d6d;
}

.menu-icon-mobile:hover {
  color: rgb(160, 151, 203);
  cursor: pointer;
}

.nav-menu-small {
  position: fixed;
  background-color: #d8d8ff;
  top: 0;
  left: 0;
  width: 80%;
  height: 100vh;
  z-index: 50;
  color: #062274;
  font-weight: lighter;
  border-right: 1px rgb(126, 117, 169) solid;
}

.menu-mask {
  background-color: rgb(11, 1, 28);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 50;
}

.nav-links li {
  padding: 10px 0;
  border-bottom: 1px rgb(160, 151, 203) solid;
  list-style-type: none;
}

.nav-links li a {
  padding-left: 25px;
  color: #062274;
}

.container-all {
  width: 90vw;
  height: auto;
  /* height: 100vh; */
  margin: 0 auto;
}

/******* Content-Home ********/
.content-home {
  margin: 0 auto;
  text-align: center;
  padding: 10px;
  width: 100%;
  height: auto;
  border-radius: 4px;
  box-shadow: 3px 2px 6px #1c0234;
  border-bottom: solid 1px rgb(160, 151, 203);
  border-right: solid 1px rgb(160, 151, 203);
}

.content-home h1 {
  color: #ededed;
  letter-spacing: 2px;
}

.content-home h4 {
  color: #e9b5fe;
  font-size: 18px;
  padding: 5px 0 0 0;
}

.content-home p {
  padding: 10px 0 0 0;
  letter-spacing: 0px;
  font-size: 15px;
  color: #ededed;
}

.github-icon {
  width: 17.5px;
  height: auto;
  position: absolute;
  right: 7%;
  margin: 3px 0 0 0;
}
.github-icon a,
.email-logo a,
.linkedin-logo a {
  color: #ededed;
}

.github-icon a:hover,
.email-logo a:hover,
.linkedin-logo a:hover {
  cursor: pointer;
  color: #e9b5fe;
}

.email-logo {
  width: 18px;
  height: auto;
  position: absolute;
  right: 9.25%;
  margin: 3px 0 0 0;
}

.linkedin-logo {
  width: 17px;
  height: auto;
  position: absolute;
  right: 11.75%;
  margin: 3px 0 0 0;
}

/*********** Projects Page ************/
.projects_container {
  width: 80vw;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  animation: fadeIn 2.5s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

.project_page_info {
  text-align: center;
  margin: 30px 0 40px 0;
}

.project_page_info img {
  width: 95%;
  height: auto;
  box-shadow: 5px 3px 3px #1c0234;
  border-bottom: solid 1px rgb(160, 151, 203);
  border-right: solid 1px rgb(160, 151, 203);
}

.project_page_info h3,
#aboutText h3 {
  width: 95%;
  height: auto;
  color: #e9b5fe;
  margin: 10px 0 0 0;
}

.project_page_info p,
#aboutText p {
  width: 95%;
  height: auto;
  color: #ededed;
  font-size: 15px;
}
.project-div a {
  text-decoration: none;
}
.project-div img:hover {
  border-radius: 20%;
  transition: 1s ease;
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}

.project_page_info button {
  width: 70px;
  height: 25px;
  background-color: #ff6d6d;
  color: #fff;
  border: none;
  border-radius: 3px;
  font-weight: bold;
  letter-spacing: 1px;
  margin: 20px 0;
  cursor: pointer;
}

.project_page_info button:hover {
  background-color: rgb(160, 151, 203);
  transform: scale(1.1, 1.1);
}
.project-div {
  width: 33.25%;
  height: auto;
}

.project-div:nth-last-of-type(1) {
  height: 880px;
}

/*********** About Page **********/
#about-page {
  display: flex;
  justify-content: center;
  position: relative;
  animation: fadeLeft 2s;
}

@keyframes fadeLeft {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}
#aboutPage-photo {
  width: 25vw;
  height: auto;
  margin: 15px 0 0 0;
  object-fit: cover;
}

#aboutText {
  border-radius: 4px;
  box-shadow: 3px 2px 6px #1c0234;
  border-bottom: solid 1px rgb(160, 151, 203);
  border-right: solid 1px rgb(160, 151, 203);
  width: 65%;
  padding: 15px 0 25px 65px;
  height: auto;
  margin: 15px 0 0 0;
}
#aboutText h4 {
  color: #e9b5fe;
  font-size: 18px;
  margin: 0 0 5px 0;
}

#aboutText p {
  font-size: 14px;
  line-height: 25px;
}
.software-home {
  width: 85%;
  height: auto;
  margin: 0 auto;
  padding: 50px 0 150px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  animation: fadeRight 2s;
}

.software {
  width: 85%;
  height: auto;
  margin: 0 auto;
  padding: 50px 0 150px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  animation: fadeRight 2s;
}

@keyframes fadeRight {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

.software-home img {
  width: 10%;
  height: auto;
}

.software img {
  width: 8%;
  height: auto;
  margin: 15px 20px 5px 0;
}

/*******  Contact Form Page *********/
#contact-form {
  width: 60%;
  height: auto;
  margin: 0 auto;
  animation: fadeDown 2s;
}

@keyframes fadeDown {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
.container-all h2 {
  text-align: center;
}

#contact-form h2 {
  font-size: 20px;
  color: #e9b5fe;
  margin-bottom: 20px;
  margin: 15px 0 0 0;
  text-align: center;
}

#contact-form form {
  width: 100%;
  height: auto;
  margin: 15px 0 0 0;
  position: relative;
}

#contact-form label {
  font-size: 14px;
  color: #f9f7f9;
}

#contact-form input {
  width: 100%;
  height: 30px;
  margin-bottom: 15px;
  border: none;
  padding-left: 5px;
}

#contact-form textarea {
  width: 100%;
  height: 130px;
  margin: 0 auto;
  position: relative;
  padding: 5px 0 0 5px;
}

#contact-form ::placeholder {
  color: rgb(201, 201, 201);
  font-family: "Roboto", sans-serif;
  font-weight: 100;
}

#contact-form button {
  background-color: #ff6d6d;
  width: 100px;
  height: 30px;
  border: none;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  font-weight: bold;
  letter-spacing: 1px;
  position: absolute;
  right: 0;
  bottom: -45px;
}

#contact-form button:hover {
  background-color: #e9b5fe;
  transform: scale(1.1, 1.1);
}

.footer-main {
  width: 100%;
  height: 60px;
  background-color: #b3d0fe;
  text-align: center;
  position: fixed;
  bottom: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #333;
  z-index: 100;
  margin-top: 30px;
}

.footer-main h2 {
  font-size: 14px;
  font-weight: lighter;
}

.footer-main h2 a {
  text-decoration: none;
  color: #062274;
}
.footer-main h2 a:hover {
  color: #ff6d6d;
}
#not-found-page {
  width: 100%;
  height: auto;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
#not-found-page img {
  width: 60%;
  height: auto;
  margin-top: 50px;
}

#not-found-page a {
  background-color: #e9b5fe;
  width: 50%;
  height: 40px;
  border: none;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  font-weight: bold;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3% 0 10% 0;
  color: #320a41;
}

#not-found-page a:hover {
  background-color: #ff6d6d;
  color: #ffffff;
}
/* EAGL project container.. */
.eagl-project {
  width: 100%;
  height: auto;
  margin-top: 3%;
  text-align: center;
}
.eagl-project h4 {
  color: #e9b5fe;
  font-size: 1.5rem;
  margin-bottom: 2%;
}
.eagl-project p {
  font-size: 1rem !important;
}
.ux-contents {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 3%;
  text-align: center;
}
.ux-container {
  width: 50%;
}
.ux-container a:first-child {
  color: #e9b5fe;
  text-decoration: none;
  display: block;
  padding-bottom: 1%;
}
.ux-container a img {
  width: 55%;
}
.userflow-container {
  width: 50%;
}
.userflow-container a img {
  width: 55%;
}
.userflow-container a:first-child {
  color: #e9b5fe;
  text-decoration: none;
  display: block;
  padding-bottom: 1%;
}
.eagl-project a:hover {
  color: #ff6d6d;
}
.eagl-project span {
  color: #e9b5fe;
}
.eagl-project img:hover {
  border: solid 0.05rem #e9b5fe;
}
.video-eagl {
  width: 77.5%;
  display: block;
  margin: 0 auto;
}
.video-eagl p {
  padding-top: 5%;
}
.video-eagl iframe {
  width: 100%;
  margin-top: -10%;
}
.vp-center {
  display: block;
}
/************** Media Query *******/
@media screen and (min-width: 1010px) {
  .nav-links {
    margin: 0 auto;
    right: 0;
    position: absolute;
    padding-top: 1.5%;
  }
  .nav-menu-small {
    background-color: transparent;
    border: none;
    left: 15%;
    width: 65%;
  }
  .nav-links li {
    display: inline-flex;
    border: none;
  }
  .nav-links li a {
    display: block;
    color: #f9f7f9;
    width: 100px;
    height: 30px;
    text-align: center;
    padding-top: 5px;
  }
  .menu-mask {
    display: none;
  }
  .video-eagl iframe {
    height: 996px;
  }
}
@media screen and (max-width: 1300px) and (min-width: 600px) {
  .header {
    margin-bottom: 50px;
  }

  .github-icon {
    right: 8%;
  }

  .email-logo {
    right: 11.5%;
  }

  .linkedin-logo {
    right: 15%;
  }
  .video-eagl iframe {
    height: 700px;
  }
}

@media screen and (max-width: 900px) and (min-width: 500px) {
  .github-icon {
    right: 11%;
  }

  .email-logo {
    right: 18%;
  }

  .linkedin-logo {
    right: 25%;
  }

  .gluay a {
    margin-bottom: 100px;
  }

  #about-page {
    width: 85%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 auto;
  }
  #aboutPage-photo {
    width: 85%;
    height: auto;
    object-fit: contain;
  }
  #aboutText {
    width: 85%;
    height: auto;
    margin-top: 10%;
  }

  .software img {
    width: 25%;
  }
  .video-eagl iframe {
    height: 500px;
  }
}

@media screen and (max-width: 600px) and (min-width: 350px) {
  .header {
    margin-bottom: 30px;
  }
  .github-icon {
    right: 11%;
  }

  .email-logo {
    right: 18%;
  }

  #aboutPage-photo {
    width: 35vw;
  }

  #aboutText p {
    font-size: 15px;
  }
  #contact-form {
    width: 100%;
    height: auto;
  }

  .project-div {
    width: 50%;
    height: 250px;
    font-size: 12px;
    margin-bottom: 25px;
  }
  .project-div p,
  .project-div button {
    display: none;
  }

  .project-div button {
    margin: -35px 0 0 0;
    text-align: center;
    position: relative;
  }

  .project-div:nth-last-of-type(1) {
    height: 480px;
  }

  .software-home img {
    width: 12%;
  }

  .software {
    margin-top: 1%;
  }

  .software img {
    width: 25%;
  }
  #about-page {
    width: 85%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 auto;
  }
  #aboutPage-photo {
    width: 85%;
    height: auto;
    object-fit: contain;
  }
  #aboutText {
    width: 85%;
    height: auto;
    margin-top: 10%;
    padding: 15px 0 25px 20px;
  }
  .linkedin-logo {
    right: 25%;
  }
  .video-eagl iframe {
    height: 300px;
  }
}
