@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  background-image: linear-gradient(to right, #5a5a79, #10101a);
  color: #fff;
  font-family: "Roboto", sans-serif;
  letter-spacing: 1px;
}
