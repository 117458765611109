.homepage_gluay {
  width: 100vw;
  height: auto;
  margin: 100px 0 0 0;
}

.gluay {
  width: 90vw;
  margin: 0 auto;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border-bottom: solid 2px rgb(160, 151, 203);
  border-right: solid 2px rgb(160, 151, 203);
}

.gluayInfo {
  width: 65vw;
  height: auto;
}

.gluay h1 {
  color: #fff;
  font-size: 50px;
}

.gluay h4 {
  font-size: 20px;
  letter-spacing: 2px;
}

.gluay h3 {
  font-size: 30px;
  letter-spacing: 2px;
}

.gluay span {
  color: #e9b5fe;
}

.gluay a {
  width: 150px;
  height: 45px;
  background-color: #ff6d6d;
  color: #fff;
  border: none;
  border-radius: 3px;
  font-weight: bold;
  letter-spacing: 1px;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  margin-top: 20px;
}

.gluay a:hover {
  background-color: rgb(160, 151, 203);
  transform: scale(1.1, 1.1);
}
.gluay_home_pic {
  width: 45vw;
  height: auto;
  object-fit: cover;
}
.gluay_home_pic img {
  width: 45vw;
  height: auto;
  margin-bottom: -4px;
  animation-duration: 2s;
  animation-name: slidein;
  animation-timing-function: ease-in-out;
}

@keyframes slidein {
  from {
    margin-left: -280%;
    margin-top: -450px;
    width: 175vw;
  }

  to {
    margin-right: 0%;
    margin-top: 0px;
    width: 45vw;
  }
}

@media screen and (max-width: 1000px) and (min-width: 400px) {
  .homepage_gluay {
    margin: 0 auto;
    text-align: center;
  }
  .gluay {
    width: 85%;
    margin: 0 auto;
  }
  .gluayInfo {
    width: 1000vw;
    margin: 0 auto;
  }

  .gluay h1 {
    font-size: 22px;
  }

  .gluay h4 {
    font-size: 15px;
  }

  .gluay h3 {
    font-size: 17px;
  }

  .gluay_home_pic img {
    display: none;
  }

  .gluay a {
    width: 120px;
    height: 40px;
    font-size: 15px;
    margin: 0 auto;
    margin-bottom: 40px;
  }
}
